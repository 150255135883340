@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300";
.coin {
  top: var(--coin-from-x, 24px);
  left: var(--coin-from-y, 24px);
  z-index: 100;
  opacity: 0;
  position: absolute;
}

.coin:after {
  content: "$";
  color: #edc46b;
  opacity: 0;
  background: #e3a217;
  border: 2px solid #edc46b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  display: flex;
}

.coin--animated, .coin--animated:after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-fill-mode: both;
}

.coin--animated {
  animation-name: coin-x-axis;
  animation-timing-function: ease-in;
}

.coin--animated:after {
  animation-name: coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@keyframes coin-x-axis {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}

.Gy87eW_text {
  font-family: IBM Plex Sans, sans-serif;
  line-height: 1;
}

.LwUTDG_hint {
  color: #969696;
  text-align: center;
  width: 100%;
  margin: 10px 0 25px;
  font-size: 16px;
  font-weight: 300;
}

._1m2oWq_circlesContainer {
  justify-content: center;
  width: 100%;
  height: 180px;
  display: flex;
}

._1m2oWq_circles {
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 400px) {
  ._1m2oWq_circlesContainer {
    box-sizing: border-box;
    padding: 0 10px;
  }
}

.tKcEXW_input {
  box-sizing: border-box;
  caret-color: #969696;
  color: #969696;
  background: #494949;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  margin-right: 5px;
  padding: 10px 0 10px 15px;
  font-size: 18px;
  font-weight: bold;
}

.tKcEXW_text {
  color: #bebebe;
  font-size: 18px;
  font-weight: 700;
}

.tbRRgG_button {
  color: #2b2b2b;
  cursor: pointer;
  background: #bebebe;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.tbRRgG_loader span:first-child {
  display: flex;
}

.GNms6q_button {
  width: 103px;
  height: 33px;
  font-size: 18px;
  font-weight: 500;
}

.GNms6q_button:disabled {
  cursor: default;
  opacity: .5;
}

.GNms6q_primary {
  color: #2b2b2b;
  background: #bebebe;
}

.GNms6q_secondary {
  color: #bebebe;
  background: none;
  border: 1px solid #3eb5f175;
  margin-top: 10px;
  transition: box-shadow .1s;
}

.GNms6q_button:not(:disabled):hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.GNms6q_nobold {
  font-weight: normal !important;
}

.DHSumq_buttons, .Ou9J5W_buttons {
  flex-direction: column;
  display: flex;
}

.WZcb5G_userCount {
  color: #969696;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
}

.wcFNia_icon {
  cursor: pointer;
}

._4XHpq_title {
  color: #bebebe;
  font-size: 18px;
  font-weight: 700;
}

.sslnla_card {
  box-sizing: border-box;
  background: #2b2b2b;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  min-height: 122px;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: flex;
}

.sslnla_leftSideContainer {
  flex-direction: column;
  display: flex;
}

.sslnla_leftSideContainer > span {
  text-overflow: ellipsis;
  width: 145px;
  overflow: hidden;
}

.WM5FBW_title {
  color: #3b3b3b;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.D2SXEW_text {
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 300;
}

.pVQoxG_bars {
  width: 160px;
  height: 4px;
  margin-top: 5px;
  display: flex;
}

.pVQoxG_bar {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  transition: transform .3s ease-out;
  display: flex;
}

.pVQoxG_bar:hover {
  transform: scale(1.5);
}

.pVQoxG_yesBar {
  background: #d24a43;
}

.pVQoxG_noBar {
  background: #3eb5f1;
}

.hWz-3G_stats {
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.hWz-3G_textContainer {
  justify-content: center;
  width: 160px;
  display: flex;
}

.hWz-3G_text {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

.hWz-3G_arrowDown {
  border-top: 8px solid #b3b3b3;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

.hWz-3G_img {
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
}

.hWz-3G_tester {
  content: "";
  z-index: 111;
  background: #b3b3b3;
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  display: none;
  position: absolute;
  bottom: 2px;
}

.hWz-3G_imgWithArrow {
  position: absolute;
  bottom: 0;
}

.hWz-3G_imgWithArrow:hover .hWz-3G_tester {
  display: flex;
}

.Orh0Eq_button {
  color: #222;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 33px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
}

.Orh0Eq_button:hover {
  box-shadow: none;
}

.Orh0Eq_yes {
  background: #d24a43;
  box-shadow: 0 3px 3.84px 2.16px #ff00002e;
}

.Orh0Eq_yes:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: #bd5d58;
}

.Orh0Eq_no {
  background: #3eb5f1;
  box-shadow: 0 3px 3.84px 2.16px #3eb5f12e;
}

.Orh0Eq_no:disabled {
  box-shadow: none;
  cursor: not-allowed;
  background: #59acd6;
}

.b4MS9q_answerButtons {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.mrTbNq_container {
  box-sizing: border-box;
  justify-content: center;
  gap: 48px;
  width: 100%;
  display: flex;
}

@media only screen and (width <= 968px) {
  .mrTbNq_container {
    justify-content: center;
    width: 100%;
    height: auto;
  }
}

.rzmFQq_container {
  box-sizing: border-box;
  cursor: pointer;
  background: #2b2b2b0f;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-top: 10px;
  padding: 30px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.rzmFQq_dropdown {
  box-sizing: border-box;
  color: #2b2b2b;
  background: #eae9e7;
  border: 1px solid #b3b3b342;
  border-radius: 4px;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  padding: 21px 18px;
  font-family: IBM Plex Sans;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.rzmFQq_dropdown > div, .rzmFQq_icon {
  cursor: pointer;
}

.rzmFQq_username {
  color: #2b2b2b;
  margin-right: 5px;
}

.rzmFQq_twitterSignIn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rzmFQq_twitterSignIn svg {
  color: #2b2b2b;
}

.rzmFQq_divider {
  color: #2b2b2b;
  cursor: initial;
  border-top: 1px solid #25252524;
  justify-content: center;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

@media only screen and (width <= 945px) {
  .rzmFQq_container {
    margin-top: 6px;
    padding: 25px 20px;
  }
}

.sLin6a_body {
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #fff, #ece9e6);
  background: linear-gradient(to right, #fff, #ece9e6);
  justify-content: center;
  width: 100vw;
  min-height: 100dvh;
  max-height: 100dvh;
  display: flex;
}

.sLin6a_close {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.sLin6a_close h2 {
  margin: 0 0 14px;
}

.sLin6a_close svg {
  cursor: pointer;
}

.sLin6a_address {
  text-overflow: ellipsis;
  max-width: 390px;
  display: block;
  overflow: hidden;
}

.sLin6a_row {
  gap: 8px;
  display: flex;
}

.sLin6a_row b {
  white-space: nowrap;
}

.sLin6a_amount {
  text-overflow: ellipsis;
  max-width: 180px;
  display: block;
  overflow: hidden;
}

.sLin6a_hide {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}

.sLin6a_modalContent {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.sLin6a_connectButton {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  line-height: 1;
  transition: color .1s;
  display: flex;
}

.sLin6a_twitterSignIn {
  color: #3c4043;
  cursor: pointer;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  font-family: Google Sans, arial, sans-serif;
  font-size: 14px;
  transition: all .2s;
  display: flex;
}

.sLin6a_twitterSignIn:hover {
  background: #4285f40a;
  border-color: #d2e3fc;
}

.sLin6a_twitterSignIn svg {
  width: 18px;
  height: 18px;
}

.sLin6a_twitterSignIn > div {
  font-family: Google Sans, arial, sans-serif;
}

.sLin6a_search {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 20%;
  padding: 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  transition: color .1s;
  display: flex;
}

.sLin6a_search svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

.sLin6a_input {
  box-sizing: border-box;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 80%;
  padding: 10px 0 10px 15px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
}

.GN153G_card {
  box-sizing: border-box;
  background: none;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.GN153G_card:last-of-type {
  margin-bottom: 0;
}

.GN153G_previewImg {
  object-fit: cover;
  aspect-ratio: 1 / 2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 180px;
}

.GN153G_innerCard {
  box-sizing: border-box;
  background: none;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 180px;
  padding: 15px 15px 10px;
  display: flex;
  position: relative;
}

.GN153G_innerCardWithImage {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.GN153G_expand {
  border-top: 1px solid #2b2b2b57;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: calc(100% - 18px);
  margin-top: 15px;
  padding-top: 10px;
  display: flex;
}

.GN153G_username {
  color: #00000063;
  cursor: pointer;
  transition: color .2s;
}

.GN153G_username:hover {
  color: #3b3b3b;
}

.GN153G_hint {
  color: #969696;
  font-size: 16px;
  font-weight: 300;
}

.GN153G_icon {
  cursor: pointer;
  color: #00000063;
  transition: all .2s !important;
}

.GN153G_icon:hover {
  color: #3b3b3b;
}

.OxsVWq_textarea {
  box-sizing: border-box;
  caret-color: #969696;
  color: #bebebe;
  resize: none;
  background: #2b2b2b;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 144px;
  padding: 10px 15px;
  font-family: IBM Plex Sans;
  font-size: 18px;
}

.OxsVWq_textarea::placeholder {
  color: #969696;
  opacity: 1;
  font-size: 18px;
}

.OxsVWq_textarea:disabled {
  cursor: not-allowed;
}

.OxsVWq_text {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}

.OGOopW_newQuestion {
  width: 100%;
  margin-top: 25px;
  position: relative;
}

.OGOopW_newQuestion textarea {
  color: #2b2b2b;
  background: #2b2b2b0f;
  font-size: 16px;
}

.OGOopW_newQuestion textarea::placeholder {
  color: #2b2b2b;
  font-size: 16px;
}

@media only screen and (width <= 400px) {
  .OGOopW_newQuestion {
    margin-top: 14px;
  }
}

@media only screen and (width <= 945px) {
  .OGOopW_newQuestion textarea {
    height: 110px !important;
  }
}

.OGOopW_button {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  position: absolute;
  bottom: -11px;
  right: 11px;
  transform: translateY(-50%);
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.OGOopW_button:not(:disabled):hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.OGOopW_button:disabled {
  cursor: not-allowed;
  background: #8d9193;
  box-shadow: 0 0 23.84px 8.16px #8d91935c;
}

.E0-4eG_groupContainer {
  flex-direction: column;
  width: 100%;
  height: calc(100% - 144px);
  display: flex;
  overflow: scroll;
}

.iW3V3q_container {
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
}

.zfn2bW_mainScreen {
  flex-direction: column;
  align-items: center;
  width: 500px;
  display: flex;
}

.zfn2bW_mainScreenContainer {
  flex-direction: column;
}

.HFaEpW_sidebar {
  flex-direction: column;
  align-items: center;
  width: 380px;
  display: flex;
  overflow-y: scroll;
}

@media only screen and (width <= 400px) {
  .HFaEpW_sidebar {
    width: 100%;
  }
}

.HFaEpW_title {
  margin-bottom: 25px;
}

._7kO5aG_cardsRow {
  flex-direction: column;
  align-items: center;
  width: 439px;
  display: flex;
}

._7kO5aG_scrollContainer {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.zDJPrW_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.zDJPrW_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  font-family: IBM Plex Sans;
  display: flex;
  overflow: scroll;
}

.zDJPrW_usersContainer {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.zDJPrW_screenWithGroupContent > p {
  color: #bebebe;
  padding: 30px 15px;
}

@media only screen and (width <= 945px) {
  .zDJPrW_screenWithGroupContent {
    display: none;
  }
}

.zDJPrW_userItem {
  color: #3b3b3b;
  cursor: pointer;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  flex-direction: column;
  gap: 24px;
  padding: 15px;
  font-family: IBM Plex Sans;
  display: flex;
}

.zDJPrW_userItem img, .zDJPrW_userItem .zDJPrW_imgFallback {
  border: 3px solid #d9d7d5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.zDJPrW_userItem svg {
  color: #3b3b3b;
}

.zDJPrW_userItem a {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  padding: 6px;
  transition: box-shadow .2s;
}

.zDJPrW_userItem a:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.zDJPrW_column {
  flex-direction: column;
  gap: 3px;
  display: flex;
}

.zDJPrW_row {
  gap: 8px;
  display: flex;
}

.zDJPrW_close {
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.zDJPrW_close h2 {
  margin: 0 0 20px;
}

.zDJPrW_close svg {
  cursor: pointer;
}

.zDJPrW_checkboxes__row {
  display: flex;
}

.zDJPrW_checkboxes__item {
  padding: 15px;
}

.zDJPrW_checkbox.zDJPrW_style-c {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 30px;
  display: inline-block;
  position: relative;
}

.zDJPrW_checkbox.zDJPrW_style-c input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.zDJPrW_checkbox.zDJPrW_style-c input:checked ~ .zDJPrW_checkbox__checkmark {
  background-color: #f7cb15;
}

.zDJPrW_checkbox.zDJPrW_style-c input:checked ~ .zDJPrW_checkbox__checkmark:after {
  opacity: 1;
}

.zDJPrW_checkbox.zDJPrW_style-c:hover input ~ .zDJPrW_checkbox__checkmark {
  background-color: #eee;
}

.zDJPrW_checkbox.zDJPrW_style-c:hover input:checked ~ .zDJPrW_checkbox__checkmark {
  background-color: #f7cb15;
}

.zDJPrW_checkbox.zDJPrW_style-c .zDJPrW_checkbox__checkmark {
  background-color: #eee;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: background-color .25s;
  position: absolute;
  top: 2px;
  left: 0;
}

.zDJPrW_checkbox.zDJPrW_style-c .zDJPrW_checkbox__checkmark:after {
  content: "";
  opacity: 0;
  border: 2px solid #333;
  border-width: 0 2px 2px 0;
  width: 5px;
  height: 10px;
  transition: opacity .25s;
  position: absolute;
  top: 4px;
  left: 8px;
  transform: rotate(45deg);
}

.zDJPrW_checkbox.zDJPrW_style-c .zDJPrW_checkbox__body {
  color: #333;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
}

._31v-EG_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

._31v-EG_backContainer {
  justify-content: end;
  width: 100%;
  display: flex;
}

._31v-EG_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  font-family: IBM Plex Sans;
  display: flex;
  overflow: scroll;
}

._31v-EG_screenWithGroupContent ._31v-EG_button {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  line-height: 1;
  transition: box-shadow .2s;
  display: flex;
}

._31v-EG_screenWithGroupContent ._31v-EG_button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

._31v-EG_screenWithGroupContent ._31v-EG_button svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

._31v-EG_similarity {
  color: #3b3b3b;
  text-align: center;
  border-top: 1px solid #b3b3b3;
  margin: 0;
  padding-top: 24px;
  padding-bottom: 9px;
  font-size: 36px;
  font-weight: 200;
}

._31v-EG_similarity span {
  font-weight: 400;
}

._31v-EG_tabs {
  background: #2b2b2b0f;
  border-radius: 4px;
  gap: 12px;
  padding: 4px;
  display: flex;
}

._31v-EG_tabs div {
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 5px 10px;
  font-family: IBM Plex Sans;
  transition: background-color .1s;
  display: flex;
}

._31v-EG_tabs div._31v-EG_selected {
  background-color: #2b2b2b14;
}

._31v-EG_differentTabs {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

._31v-EG_differentTabs2 {
  width: 80%;
  margin: 0 auto;
}

._6W1esa_input {
  box-sizing: border-box;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 36px;
  padding: 10px 0 10px 15px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
}

._6W1esa_search {
  cursor: pointer;
  color: #3b3b3b;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  line-height: 1;
  transition: color .1s;
  display: flex;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

._6W1esa_search svg {
  color: #3b3b3b;
  margin-left: 8px;
  transition: color .1s;
}

._6W1esa_text {
  color: #bebebe;
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  position: relative;
}

._6W1esa_dropdownContainer {
  box-sizing: border-box;
  width: 138px;
  font-size: 16px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 95px;
  transform: translateY(-50%);
}

._6W1esa_dropdownContainer svg {
  color: #3b3b3b;
  cursor: pointer;
  height: 18px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

._6W1esa_dropdownValue {
  cursor: pointer;
  color: #3b3b3b;
  box-sizing: border-box;
  background: none;
  border: 1px solid #59acd6;
  border-radius: 4px;
  padding: 9px 40px 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  line-height: 1;
  transition: color .1s;
}

._6W1esa_dropdownItemsContainer {
  box-sizing: border-box;
  color: #3b3b3b;
  z-index: 999;
  cursor: pointer;
  background: #f4f3fd;
  border-radius: 4px;
  flex-direction: column;
  width: 100%;
  margin-top: -3px;
  padding: 0 4px;
  font-family: IBM Plex Sans, sans-serif;
  transition: color .1s;
  display: flex;
  position: absolute;
  bottom: 0;
  box-shadow: 0 3px 3.84px 1.16px #0c0c0c2b;
}

._6W1esa_dropdownItemsContainer > div {
  border-top: 1px solid #3b3b3b21;
  padding: 9px 0 9px 16px;
}

._6W1esa_dropdownItemsContainer > div span {
  margin-top: 1px;
  margin-left: -3px;
  display: block;
}

._6W1esa_dropdownItemsContainer > div:first-child {
  border-top: none;
}

._6W1esa_buttonsOutside {
  margin-top: 10px;
  flex-direction: column !important;
  gap: 8px !important;
}

._6W1esa_buttonsOutside ._6W1esa_dropdownValue {
  border: none;
}

._6W1esa_buttonsOutside ._6W1esa_dropdownContainer {
  border-radius: 4px;
  top: 0;
  right: 0;
  position: relative !important;
}

._6W1esa_buttonsOutside ._6W1esa_dropdownContainer, ._6W1esa_buttonsOutside ._6W1esa_search {
  background: #2b2b2b0f;
  border: none;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  transform: none;
}

@media only screen and (width <= 386px) {
  ._6W1esa_text {
    flex-direction: column;
    gap: 8px;
  }

  ._6W1esa_dropdownValue {
    border: none;
  }

  ._6W1esa_dropdownContainer {
    border-radius: 4px;
    top: 0;
    right: 0;
    position: relative !important;
  }

  ._6W1esa_dropdownContainer, ._6W1esa_search {
    background: #f4f3fd;
    border: none;
    width: 100%;
    position: static;
    transform: none;
  }
}

@media only screen and (width <= 945px) {
  ._6W1esa_text {
    font-size: 16px;
  }

  ._6W1esa_text button {
    color: #f4f3fd;
    font-size: 16px;
    background: #3b3b3b !important;
  }

  ._6W1esa_text button svg {
    color: #f4f3fd;
  }

  ._6W1esa_text input {
    color: #f4f3fd;
    caret-color: #f4f3fd;
    background: #3b3b3b;
    height: 36px;
    font-size: 16px;
  }

  ._6W1esa_text input::placeholder {
    color: #f4f3fd;
  }

  ._6W1esa_text ._6W1esa_dropdownContainer {
    color: #f4f3fd;
    background: #3b3b3b;
  }

  ._6W1esa_text ._6W1esa_dropdownContainer svg, ._6W1esa_text ._6W1esa_dropdownValue, ._6W1esa_text ._6W1esa_dropdownValue svg {
    color: #f4f3fd;
  }
}

.BbEWnq_totalAndBack {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.BbEWnq_total {
  color: #3b3b3b;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 300;
}

.BbEWnq_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.BbEWnq_screenWithGroupContent {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  font-family: IBM Plex Sans;
  display: flex;
  overflow: scroll;
}

._9WjUxq_back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

._9WjUxq_totalAndBack {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

._9WjUxq_total {
  color: #3b3b3b;
  font-family: IBM Plex Sans;
  font-size: 18px;
  font-weight: 300;
}

._9WjUxq_usersContainer {
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 20px);
  max-width: 500px;
  height: calc(100dvh - 20px);
  margin-top: 10px;
  font-family: IBM Plex Sans;
  display: flex;
  overflow: scroll;
}

._9WjUxq_usersContainer > p {
  color: #3b3b3b;
  padding: 30px 15px;
}

._9WjUxq_userItem {
  color: #3b3b3b;
  cursor: pointer;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  justify-content: space-between;
  gap: 12px;
  padding: 15px;
  font-family: IBM Plex Sans;
  display: flex;
}

._9WjUxq_userItem img {
  border: 3px solid #d9d7d5;
  border-radius: 50%;
  height: 50px;
}

._9WjUxq_userItem svg {
  color: #3b3b3b;
}

._9WjUxq_userItem a {
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  padding: 6px;
  transition: box-shadow .2s;
}

._9WjUxq_userItem a:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

._9WjUxq_column {
  flex-direction: column;
  display: flex;
}

._9WjUxq_row {
  gap: 8px;
  display: flex;
}

._9WjUxq_usersItems {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.ue_oWq_text {
  color: #bebebe;
  flex-direction: column;
  gap: 16px;
  margin-top: 64px;
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  position: relative;
}

.ue_oWq_text:before {
  content: "⬤";
  font-size: 6px;
  position: absolute;
  top: -32px;
  right: 50%;
  transform: translateX(-50%);
}

.ue_oWq_dropdownContainer {
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 241px;
  transition: color .1s;
  position: relative;
}

.ue_oWq_dropdownContainer svg {
  color: #3b3b3b;
  cursor: pointer;
  height: 18px;
  transition: color .1s;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.ue_oWq_dropdownValue {
  cursor: pointer;
  color: #3b3b3b;
  box-sizing: border-box;
  white-space: nowrap;
  background: #2b2b2b0f;
  border: none;
  border-radius: 4px;
  padding: 9px 40px 9px 16px;
  font-family: IBM Plex Sans, sans-serif;
  line-height: 1;
  transition: color .1s;
}

.ue_oWq_dropdownItemsContainer {
  box-sizing: border-box;
  color: #3b3b3b;
  z-index: 999;
  cursor: pointer;
  background: #f4f3fd;
  border-radius: 4px;
  flex-direction: column;
  width: 241px;
  margin-top: -3px;
  padding: 0 4px;
  font-family: IBM Plex Sans, sans-serif;
  transition: color .1s;
  display: flex;
  position: absolute;
  bottom: 0;
  box-shadow: 0 3px 3.84px 1.16px #0c0c0c2b;
}

.ue_oWq_dropdownItemsContainer > div {
  border-top: 1px solid #3b3b3b21;
  padding: 9px 0 9px 16px;
}

.ue_oWq_dropdownItemsContainer > div span {
  margin-top: 1px;
  margin-left: -3px;
  display: block;
}

.ue_oWq_dropdownItemsContainer > div:first-child {
  border-top: none;
}

.ue_oWq_dropdownWithLabel {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

@media only screen and (width <= 386px) {
  .ue_oWq_text {
    flex-direction: column;
    gap: 8px;
  }

  .ue_oWq_dropdownValue {
    border: none;
  }

  .ue_oWq_dropdownContainer {
    border-radius: 4px;
    top: 0;
    right: 0;
    position: relative !important;
  }

  .ue_oWq_dropdownContainer, .ue_oWq_search {
    background: #f4f3fd;
    border: none;
    width: 100%;
    position: static;
    transform: none;
  }
}

@media only screen and (width <= 945px) {
  .ue_oWq_text {
    font-size: 16px;
  }

  .ue_oWq_text span {
    color: #3b3b3b;
    font-weight: 300;
  }

  .ue_oWq_text .ue_oWq_dropdownContainer {
    color: #f4f3fd !important;
    background: #3b3b3b !important;
  }

  .ue_oWq_text .ue_oWq_dropdownContainer svg, .ue_oWq_text .ue_oWq_dropdownValue {
    color: #f4f3fd !important;
  }
}

.WWPmHq_card {
  box-sizing: border-box;
  background: none;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100dvh;
  margin-bottom: 10px;
  padding: 15px 0 10px;
  display: flex;
  overflow: hidden;
}

.WWPmHq_card:last-of-type {
  margin-bottom: 0;
}

.WWPmHq_username {
  color: #00000063;
  white-space: pre;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  line-height: 1;
  transition: color .2s;
  display: flex;
}

.WWPmHq_usernameActual {
  cursor: pointer;
}

.WWPmHq_usernameActual:hover {
  color: #3b3b3b;
}

.WWPmHq_hint {
  color: #969696;
  font-size: 16px;
  font-weight: 300;
}

.WWPmHq_stats {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.WWPmHq_stats > div {
  width: 100%;
}

.WWPmHq_textContainer {
  justify-content: center;
  width: 160px;
  margin-top: 24px;
  display: flex;
}

.WWPmHq_text {
  justify-content: center;
  margin-top: 5px;
  display: flex;
}

.WWPmHq_questionText {
  font-size: 178px;
  font-weight: 200;
}

.WWPmHq_respondButton {
  color: #3b3b3b;
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-family: IBM Plex Sans;
  font-size: 18px;
  display: flex;
}

.WWPmHq_respondButton svg {
  color: #3b3b3b;
}

.WWPmHq_arrowDown {
  border-top: 13px solid #b3b3b3;
  border-left: 13px solid #0000;
  border-right: 13px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.WWPmHq_img {
  z-index: 99999;
  border: 3px solid #b3b3b3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
}

.WWPmHq_imgWithArrow {
  margin-bottom: 4px;
  position: relative;
}

.WWPmHq_bars {
  height: 14px;
}

.WWPmHq_barsMinWidth > div {
  min-width: 102px;
}

.WWPmHq_close {
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  padding-right: 24px;
  display: flex;
}

.WWPmHq_close h2 {
  margin: 0 0 20px;
}

.WWPmHq_close svg {
  cursor: pointer;
}

.SmrJ8W_chat {
  width: 500px;
  padding: 10px 0;
}

.SmrJ8W_messageBoard {
  flex-flow: column wrap;
  width: 100%;
  height: calc(100dvh - 254px);
  display: flex;
  overflow-y: scroll;
}

.SmrJ8W_messageBoard::-webkit-scrollbar {
  display: none;
}

.SmrJ8W_message {
  background-color: #f7f7f7;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  width: 50%;
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
}

.SmrJ8W_message:last-child {
  margin-bottom: 0;
}

.SmrJ8W_mine {
  align-self: flex-end;
}

.SmrJ8W_notMine {
  align-self: flex-start;
}

.SmrJ8W_inputGroup {
  z-index: 1;
  flex-flow: row;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 8px;
  font-size: 1.14em;
  display: flex;
  position: relative;
  bottom: 0;
}

.SmrJ8W_input {
  color: #2b2b2b;
  background: #2b2b2b0f;
  border: 0;
  border-radius: 4px;
  outline: 0;
  flex-grow: 1;
  height: 144px;
  font-size: 16px;
}

.SmrJ8W_input:focus {
  background-color: #ffffffbf;
}

.SmrJ8W_input::-webkit-input-placeholder {
  color: #21212140;
}

.SmrJ8W_btn {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  position: absolute;
  bottom: -16px;
  right: 16px;
  transform: translateY(-50%);
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.SmrJ8W_btn:hover {
  border-bottom-color: #1e528f;
}

.SmrJ8W_picture {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 50%;
  height: 36px;
  margin-right: 15px;
}

.SmrJ8W_changeGroupBtn {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 3px 3.84px 2.16px #3eb5f12e;
}

.SmrJ8W_top {
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 20px;
  display: flex;
}

.SmrJ8W_currentGroup {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.aq6KZW_landing-button {
  cursor: pointer;
  color: #3b3b3b;
  text-align: center;
  background: none;
  border: none;
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  position: absolute;
  bottom: 8px;
}

.aq6KZW_slides {
  background: linear-gradient(to right, #fff, #ece9e6);
  width: 100%;
  overflow: scroll;
}

.aq6KZW_mobile-second-text {
  display: none;
}

.aq6KZW_landing {
  color: #3b3b3b;
  text-align: center;
  letter-spacing: -3.51667px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 110px;
  font-weight: 500;
  display: flex;
  position: relative;
}

@supports (height: 100svh) {
  .aq6KZW_landing {
    height: 100svh;
  }
}

@media only screen and (width <= 380px) {
  .aq6KZW_landing .aq6KZW_name {
    font-size: 42px !important;
  }

  .aq6KZW_landing .aq6KZW_subtitle {
    font-size: 22px !important;
  }

  .aq6KZW_landing .aq6KZW_open-app-button {
    margin-top: 30px;
    padding: 12px 16px;
    font-size: 18px;
  }

  .aq6KZW_landing .aq6KZW_open-app-button svg {
    font-size: 16px;
  }
}

.aq6KZW_svgWrapper {
  width: 25px;
  height: 18px;
}

.aq6KZW_svgWrapper svg {
  width: auto;
  height: 18px;
  padding-right: 6px;
}

.aq6KZW_landing .aq6KZW_name {
  color: #232323;
  font-size: 60px;
}

h1, h2 {
  margin: 0;
}

.aq6KZW_landing .aq6KZW_additional-text {
  color: #616161;
  font-size: 30px;
}

.aq6KZW_landing .aq6KZW_subtitle {
  color: #616161;
  font-size: 40px;
  font-weight: 300;
}

.aq6KZW_landing .aq6KZW_smaller {
  letter-spacing: -1.5px;
  margin-top: 10px;
  font-size: .3em;
  font-weight: 200;
}

.aq6KZW_scrollIcon {
  border: 1px solid #bfbfbf;
  border-radius: 30px;
  justify-content: center;
  width: 15.4px;
  height: 27px;
  display: flex;
  position: absolute;
  bottom: 39px;
}

.aq6KZW_scrollIcon:after {
  content: "Scroll Down";
  color: #bfbfbf;
  letter-spacing: 1px;
  text-align: center;
  width: 100px;
  margin-top: 10px;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  font-weight: 300;
  animation: 2s infinite aq6KZW_scrollText;
  display: block;
  position: absolute;
  bottom: 0;
}

i.aq6KZW_scroll {
  background: #bfbfbf;
  border-radius: 40px;
  width: 2px;
  height: 5px;
  animation: 2s infinite aq6KZW_scrollWheel;
  display: block;
}

.aq6KZW_open-app-button {
  letter-spacing: -.5px;
  box-sizing: border-box;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  padding: 12px 18px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.aq6KZW_open-app-button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}

.aq6KZW_privacy {
  letter-spacing: 0;
  color: gray;
  white-space: nowrap;
  font-weight: 200;
  line-height: 15px;
}

.aq6KZW_privacy a {
  color: inherit;
  font-size: 15px;
  text-decoration: none;
}

.aq6KZW_made-by {
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 200;
  line-height: 14px;
}

.aq6KZW_made-by a {
  color: inherit;
  font-family: Dancing Script;
  font-size: 20px;
  text-decoration: none;
}

.aq6KZW_icons {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-top: 90px;
  font-size: 15px;
  display: flex;
}

@media only screen and (width <= 1148px) {
  .aq6KZW_landing {
    font-size: 90px;
  }
}

@media only screen and (width <= 843px) {
  .aq6KZW_landing {
    font-size: 70px;
  }

  .aq6KZW_landing .aq6KZW_text {
    flex-direction: column;
    gap: 16px;
    display: flex;
  }
}

@media only screen and (width <= 606px) {
  .aq6KZW_landing {
    letter-spacing: -1.5px;
    font-size: 50px;
  }

  .aq6KZW_landing .aq6KZW_text {
    flex-direction: column;
    gap: 16px;
    display: flex;
  }

  .aq6KZW_scrollIcon {
    left: 45px;
  }

  .aq6KZW_mobile-second-text {
    display: block;
  }

  .aq6KZW_mobile-second-text p {
    font-size: .6em;
  }

  .aq6KZW_mobile-second-text p:last-child {
    font-size: .4em;
  }

  .aq6KZW_second-text-container {
    display: none;
  }

  .aq6KZW_landing .aq6KZW_subtitle {
    font-size: 30px;
    font-weight: 300;
  }

  .aq6KZW_landing .aq6KZW_additional-text {
    margin-top: 14px;
  }

  .aq6KZW_landing .aq6KZW_scrollIcon {
    border: 1px solid #bfbfbf;
    width: 17px;
    height: 29px;
  }

  .aq6KZW_landing .aq6KZW_scrollIcon:after {
    font-size: 14px;
  }

  i.aq6KZW_scroll {
    width: 2px;
    height: 5px;
  }
}

.aq6KZW_icon {
  color: #fff;
  z-index: 101;
  background: #06f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.aq6KZW_coin {
  top: var(--coin-from-x, 24px);
  left: var(--coin-from-y, 24px);
  z-index: 100;
  opacity: 0;
  position: absolute;
}

.aq6KZW_coin:after {
  content: "$";
  color: #edc46b;
  opacity: 0;
  background: #e3a217;
  border: 2px solid #edc46b;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  display: flex;
}

.aq6KZW_coin--animated, .aq6KZW_coin--animated:after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-fill-mode: both;
}

.aq6KZW_coin--animated {
  animation-name: aq6KZW_coin-x-axis;
  animation-timing-function: ease-in;
}

.aq6KZW_coin--animated:after {
  animation-name: aq6KZW_coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@supports ((-webkit-backdrop-filter: blur()) or (backdrop-filter: blur())) {
  .aq6KZW_search-modal {
    backdrop-filter: blur(6px);
    background: #f4f31c !important;
  }
}

@keyframes aq6KZW_scrollWheel {
  0% {
    opacity: 1;
    transform: translateY(40%);
  }

  100% {
    opacity: .7;
    transform: translateY(130%);
  }
}

@keyframes aq6KZW_scrollText {
  0% {
    opacity: 1;
    transform: translateY(120%);
  }

  100% {
    opacity: 0;
    transform: translateY(180%);
  }
}

@keyframes aq6KZW_coin-x-axis {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes aq6KZW_coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

* {
  scrollbar-width: none;
}

.screen-with-group-content {
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  width: 500px;
  font-family: IBM Plex Sans;
  display: flex;
  box-shadow: 0 3px 3.84px 2.16px #0c0c0c2b;
}

.screen-with-group-content > p {
  padding: 30px 15px;
}

.ReactModal__Overlay {
  font-family: IBM Plex Sans;
  background-color: #0000004d !important;
}

.ReactModal__Content {
  width: 80%;
  max-width: 538px;
  border: none !important;
}

#question-text {
  font-size: 178px;
}

* {
  font-family: IBM Plex Sans, sans-serif;
}

body {
  margin: 0;
  font-family: IBM Plex Sans, sans-serif;
}

::-webkit-scrollbar {
  background: none;
  width: 0;
}

body > div > div > main {
  margin: 0 !important;
}

.mobile-messages-container {
  height: calc(100% - 254px);
  margin-top: 10px;
}

.test-toast {
  top: 10px !important;
}

@media only screen and (width <= 945px) {
  .mobile-messages-container {
    height: calc(100% - 240px);
  }

  .question-search-container, .users-search-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }

  .search-input-container {
    width: calc(100dvw - 20px) !important;
  }

  .test-toast > div {
    justify-content: center !important;
  }
}

@media only screen and (width <= 1040px) {
  body > div > div > main {
    gap: 32px !important;
  }
}

@media only screen and (width <= 958px) {
  body > div > div > main {
    gap: 24px !important;
  }
}

.test-toast > div > div {
  box-sizing: border-box;
  border-radius: 24px;
  min-width: 310px;
  max-width: 340px;
  color: #fff !important;
  background: #333 !important;
}

.test-toast > div > div > div {
  margin: 4px 0 !important;
}

textarea::placeholder {
  font-family: IBM Plex Sans, sans-serif;
}

p {
  margin: 0;
}

.reset-filters-button {
  cursor: pointer;
  color: #0c0c0c;
  text-align: center;
  background: #d1caca;
  border: none;
  border-radius: 20px;
  width: 60px;
  font-family: IBM Plex Sans, sans-serif;
  line-height: 22px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.landing-button {
  cursor: pointer;
  color: #3b3b3b;
  text-align: center;
  background: none;
  border: none;
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  position: absolute;
  bottom: 8px;
}

@media only screen and (width <= 606px) {
  .test-toast > div > div {
    box-sizing: border-box;
    color: #2b2b2b;
    width: calc(100% - 20px);
    max-width: unset;
    background: #e6e3e1;
  }

  .test-toast {
    left: 0 !important;
    right: 0 !important;
  }
}

.connect-wallet-toast-button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  text-decoration: underline;
}

.icon {
  color: #fff;
  z-index: 101;
  background: #06f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

@supports ((-webkit-backdrop-filter: blur()) or (backdrop-filter: blur())) {
  .search-modal {
    backdrop-filter: blur(6px);
    background: #f4f3fd3b !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

.open-app-button {
  letter-spacing: -.5px;
  box-sizing: border-box;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  padding: 12px 18px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.open-app-button:hover {
  box-shadow: 0 3px 9.84px -.84px #3eb5f12e;
}
/*# sourceMappingURL=index.93072624.css.map */
