
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

* {
  scrollbar-width: none;
}

.screen-with-group-content {
  width: 500px;
  background: white;
  border-radius: 4;
  box-shadow: 0px 3px 3.84px 2.16px rgba(12, 12, 12, 0.17);
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
}

.screen-with-group-content > p {
  padding: 30px 15px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3)!important;
  font-family: 'IBM Plex Sans';
}

.ReactModal__Content {
  border: none!important;
  max-width: 538px;
  width: 80%;
}
/* 
.custom-groups-content {
  height: calc(100dvh - 40px);
} */

#question-text {
  font-size: 178px;
}
/* 
article {
  min-height: unset!important;
} */